import * as React from "react";
import {
  Container, Grid, Header, Segment,
} from "semantic-ui-react";
import '../../css/policy.css';
import MainTopBg from "../backgrounds/others/MainTopBg";
import SEO from "../seo";


export default class Policy extends React.Component {
  render(): React.ReactElement {
    return (
      <MainTopBg style={{backgroundSize: 'contain', backgroundPosition: 'top'}}>
        <Segment basic id='policy-container'>
          <SEO title="Polityka prywatności"/>
          <Segment basic>
            <Container>
              <Grid>
                <Grid.Column>
                  <p>
                    <Header as='h1' textAlign="center">Polityka Prywatności Danych Osobowych <span>użytkowników usług świadczonych w Sklepie Internetowym pod adresem www.pure4me.pl</span></Header>
                    <ol>
                      <li>Nazwa i dane kontaktowe administratora danych</li>
                      <br/>
                      <p>Administratorem danych osobowych (w rozumieniu art. 4 pkt. 7 RODO) Klientów, korzystających z funkcjonalności Sklepu Internetowego jest Usługodawca, to jest Spółka Natur Produkt Zdrovit Sp. z o. o. ul. Nocznickiego
                        31, 01-918 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla. m. st. Warszawy w Warszawie, XIII Wydział gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 80429, NIP:
                        118-00-09-700, kapitał zakładowy 201 500,00 zł, z którym istnieje możliwość kontaktu poprzez adres mailowy: <a href="mailto:rodo@zdrovit.pl">rodo@zdrovit.pl</a>;
                      </p>

                      <li>Kogo dotyczy ta polityka prywatności?</li>
                      <br/>
                      <p>Niniejsza polityka prywatności dotyczy wszystkich osób korzystających ze Sklepu Internetowego i usług świadczonych w domenie <a href="https://www.pure4me.pl">www.pure4me.pl</a> , w szczególności:
                      </p>
                      <p>
                        <ol type="a">
                          <li> osób składających zamówienia w Sklepie Internetowym pod tym samym adresem,</li>
                          <li> osób wypełniających ankietę przed złożeniem zamówienia w Sklepie Internetowym,</li>
                          <li> osób zakładających konto w Sklepie Internetowym,</li>
                          <li> osób zamawiających usługę bezpłatnej subskrypcji (newsletter),</li>
                          <li> osób kontaktujących się za pośrednictwem formularza kontaktowego.</li>
                        </ol>
                      </p>
                      <li>Cele przetwarzania danych osobowych:</li>
                      <br/>
                      <p>
                        <ol type="a">
                          <li>przyjmowania zamówień oraz realizacji umowy sprzedaży (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),
                          </li>
                          <li>bieżącej komunikacji w sprawach związanych ze złożonymi Zamówieniami, w tym ich potwierdzaniem i informowaniem o statusie (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),
                          </li>
                          <li>umożliwienia Rejestracji oraz obsługi Konta Klienta założonego w ramach Sklepu Internetowego oraz zapewniania innych funkcjonalności za pośrednictwem Sklepu Internetowego określonych w Regulaminie w ramach
                            zawartej z Klientem umowy o świadczenie usług drogą elektroniczną (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),
                          </li>
                          <li>rozpatrywania reklamacji związanych z zawartymi umowami sprzedaży (art. 6 ust. 1 lit. b RODO),
                          </li>
                          <li> rozpatrywania reklamacji dotyczących usług świadczonych drogą elektroniczną (art. 6 ust. 1 lit. b RODO),
                          </li>
                          <li>przyjmowania i obsługi innych niż reklamacje oraz sprawy związane z realizowanymi umowami, zgłoszeń i zapytań kierowanych do Usługodawcy (np. za pośrednictwem danych kontaktowych wskazanych na stronie
                            internetowej Sklepu), co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO,
                          </li>
                          <li>przyjmowania oświadczeń odstąpienia od zawartych umów sprzedaży na odległość, zgodnie z postanowieniami niniejszego Regulaminu oraz przepisami ustawy z dnia 30 maja 2014 r. o prawach konsumenta, co stanowi
                            prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
                          </li>
                          <li>rozpatrywania i dochodzenia roszczeń, obrony przed roszczeniami, a także w celach realizacji pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, co stanowi prawnie uzasadniony interes
                            Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
                          </li>
                          <li>umożliwienia korzystania z płatności elektronicznych, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
                          </li>
                          <li>monitorowania sposobu korzystania z usług świadczonych w ramach Sklepu przez Użytkownika, pod kątem przestrzegania postanowień Regulaminu, a także rozwijania funkcjonalności Sklepu, poprawy działania świadczonych
                            usług za jego pośrednictwem, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
                          </li>
                          <li>realizacji wymagań prawnych w zakresie przepisów podatkowych i rachunkowych, w szczególności określonych przepisami ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (VAT), ustawy z dnia z dnia 15
                            lutego 1992 r. o podatku dochodowym od osób prawnych oraz ustawy z dnia 29 września 1994 r. o rachunkowości (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. c RODO),
                          </li>
                          <li>przechowywania danych dla celów archiwizacyjnych oraz na potrzeby wykazywania prawidłowości spełnienia obowiązków prawnych spoczywających na Usługodawcy, co stanowi jego prawnie uzasadniony interes (podstawa
                            prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
                          </li>
                          <li>wysyłania informacji handlowych drogą elektroniczną w postaci Newslettera – jeśli konkretna osoba wyraziła odrębną zgodę na otrzymywanie informacji handlowych drogą elektroniczną,
                          </li>
                          <li>zapisywania danych w postaci plików cookies, gromadzenia danych ze strony internetowej Sklepu oraz wersji mobilnej Sklepu – jeśli konkretna osoba wyraziła na to odrębną zgodę na zasadach określonych w Polityce
                            Plików Cookies obowiązującej na stronie internetowej Sklepu.
                          </li>
                        </ol>
                      </p>
                      <li>W jaki sposób pozyskiwane są dane osobowe?</li>
                      <br/>
                      <p>
                        <p>Dane osobowe gromadzone są bezpośrednio od osób, których dane dotyczą, tj. w szczególności poprzez wypełnienie formularza na stronie Sklepu Internetowego w celu:
                        </p>
                        <ol type="a">
                          <li>złożenia zamówienia,</li>
                          <li>przygotowania spersonalizowanego suplementu diety</li>
                          <li>założenia Konta Klienta</li>
                          <li>wysłania zapytania przez formularz,</li>
                          <li>zapisu na Newsletter.</li>
                        </ol>
                      </p>
                      <li>Jakie dane osobowe mogą być przetwarzane?</li>
                      <br/>
                      <p>
                        <p>Sklep Internetowy przetwarza dane niezbędne do świadczenia usług lub realizacji złożonego zamówienia, tj.:
                        </p>
                        <ol type="a">
                          <li>w przypadku złożenia zamówienia lub procesu rejestracji konta w Sklepie Internetowym : imię i nazwisko, adres, adres poczty elektronicznej, numer telefonu, NIP, nazwa firmy,
                          </li>

                          <li>w przypadku wypełnienia ankiety w celu przygotowania spersonalizowanego suplementu diety: imię, adres poczty elektronicznej
                          </li>
                          <li>w przypadku złożenia zapytania przez formularz kontaktowy wymagane jest podanie takich danych, jak: imię i nazwisko, adres e-mail. Osoba może także podać opcjonalnie: firmę, numer telefonu, i inne dane podane
                            dobrowolnie przez osobę w temacie lub treści wiadomości,
                          </li>
                          <li>w przypadku zamówienia Newslettera wymagany jest adres poczty elektronicznej,</li>
                        </ol>
                      </p>
                      <li>Czy podanie danych jest wymagane?</li>
                      <br/>
                      <p>Podanie danych jest niezbędne dla dokonania Rejestracji, realizacji Zamówienia, założenia Konta Klienta, zamówienia Newslettera, a także udzielenia odpowiedzi ze strony Sklepu. Brak podania wymaganych danych
                        uniemożliwia realizację zamówienia i kontaktu.
                      </p>
                      <p>
                        Podanie danych o stanie zdrowia jest dobrowolne, lecz niezbędne do skorzystania z wybranych usług administratora.
                      </p>
                      <li>Jak długo będą przetwarzane dane osobowe?</li>
                      <br/>
                      <p>
                        <p>Dane osobowe są przetwarzane przez okres niezbędny do realizacji celu, dla którego zostały zebrane. Pozyskane dane osobowe będą przechowywane przez Usługodawcę przez okres realizacji zawartych Umów sprzedaży i do
                          czasu ich prawidłowego rozliczenia, a także przez okres świadczenia usług Sklepu Internetowego przez czas obowiązywania umów o świadczenie usług drogą elektroniczną, a ponadto:
                        </p>
                        <ol type="a">
                          <li>do czasu przedawnienia potencjalnych roszczeń wynikających z umów wskazanych powyżej,</li>
                          <li>przez czas niezbędny do dochodzenia konkretnych roszczeń, z którymi wystąpił Usługodawca lub ich odparcia (jeżeli z roszczeniami wystąpił Klient) w związku z zawartymi umowami, o których mowa wyżej,
                          </li>
                          <li>przez czas wykonywania obowiązków wynikających z przepisów prawa, w tym w szczególności w zakresie regulacji podatkowych i rachunkowych, np. obowiązków związanych z przechowywaniem dokumentacji zgodnie z
                            wymaganiami art. 74 ustawy z dnia 29 września 1994 r. o rachunkowości,
                          </li>
                          <li>przez okres niezbędny do udokumentowania przez Usługodawcę przed organami administracji publicznej, w tym organem nadzoru w zakresie ochrony danych osobowych, prawidłowości spełnienia obowiązków prawnych na nim
                            spoczywających,
                          </li>
                          <li>w celach archiwizacyjnych, gdy dotyczy to historii prowadzonej korespondencji i udzielanych odpowiedzi na zgłaszane zapytania (niezwiązane bezpośrednio z zawartymi umowami) – przez okres nie dłuższy niż 3 lata,
                          </li>
                          <li>do czasu wycofania zgody na przetwarzanie danych lub ich dezaktualizacji (stwierdzonej przez Usługodawcę) – jeżeli przetwarzanie danych odbywa się za zgodą udzieloną przez konkretną osobę.
                          </li>
                        </ol>
                      </p>
                      <li>Jakie prawa przysługują osobie, której dane dotyczą?</li>
                      <br/>
                      <p>
                        <p>Usługodawca zapewnia każdemu Klientowi, prawo skorzystania ze wszystkich przysługujących im uprawnień przewidzianych na gruncie RODO w tym w szczególności:
                        </p>
                        <ol type="a">
                          <li>prawo dostępu do treści danych i ich sprostowania - w tym celu należy skorzystać z opcji zmiany danych dostępnych w ramach panelu Konta klienta (jeśli osoba założyła konto) lub skontaktować się z administratorem
                            danych,
                          </li>
                          <li>prawo do cofnięcia zgody w dowolnym momencie, jeśli podstawą przetwarzania danych była zgoda osoby, której dane dotyczą - w przypadku założenia Konta Klienta, Newslettera. Odwołanie zgody nie wpływa na zgodność z
                            prawem przetwarzania dokonanego na jej podstawie, przed jej cofnięciem. W przypadku odwołania zgody na wysyłkę Newslettera osoba może skorzystać z opcji wypisania się, dostępnej w stopce każdej wiadomości,
                          </li>
                          <li>prawo do usunięcia danych (bycia zapomnianym), o ile nie obowiązują inne przepisy prawa, które zobowiązują administratora danych do archiwizacji danych przez określony okres czasu,
                          </li>
                          <li>prawo do przenoszenia danych, o ile podstawą przetwarzania danych jest umowa lub zgoda osoby, której dane dotyczą, a przetwarzanie danych odbywa się w sposób zautomatyzowany,
                          </li>
                          <li>prawo do wniesienia sprzeciwu na przetwarzanie danych w celach marketingu bezpośredniego, realizowanego przez administratora danych w ramach usprawiedliwionego interesu prawnego, a także ograniczenia
                            przetwarzania,
                          </li>
                          <li>prawo do niepodlegania automatycznego profilowaniu, jeśli administrator danych podejmowałby decyzje opierające się wyłącznie na automatycznym profilowaniu i niosące ze sobą skutki prawne dla osoby, której dane
                            dotyczą lub podobnie na nią wpływały,
                          </li>
                          <li>prawo do kontroli przetwarzania danych i informacji na temat tego, kto jest administratorem danych, a także uzyskania informacji o celu, zakresie i sposobie przetwarzania danych, treści tych danych, źródle
                            danych, a także sposobie udostępniania, w tym o odbiorcach lub kategoriach odbiorców danych,
                          </li>
                          <li>prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (PUODO), jeśli osoba uzna, że przetwarzanie jej danych jest niezgodne z aktualnie obowiązującymi przepisami w tym zakresie. W celu realizacji
                            powyższych praw osoby, której dane dotyczą może się skontaktować z administratorem danych osobowych.
                          </li>
                        </ol>
                      </p>
                      <li>Czy dane mogą zostać przekazane innym odbiorcom danych?</li>
                      <br/>
                      <p>
                        Dane osobowe Klientów mogą być udostępniane następującym kategoriom odbiorców:
                        <ol type="a">
                          <li>podwykonawcom zapewniającym wsparcie techniczne Usługodawcy w prowadzeniu i utrzymaniu, a także rozwoju Sklepu takim jak: podmioty świadczące usługi hostingu, dostawcy oprogramowania do zarządzania Sklepem,
                            podmioty świadczące obsługę techniczną oprogramowania Sklepu, dostawcy zapewniający oprogramowanie do wysyłania korespondencji handlowej drogą elektroniczną, agencje marketingowe, dostawcy narzędzi diagnostycznych
                            (np. związanych z ruchem na stronie internetowej Sklepu), z którymi Usługodawca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych;
                          </li>
                          <li>podmiotom wspierającym realizację przez Usługodawcę obowiązujących przepisów prawa oraz uprawnień i obowiązków wynikających z niniejszego Regulaminu, w związku ze świadczeniem usług za pośrednictwem Sklepu, takim
                            jak podmioty świadczące usługi doradcze, z którymi Usługodawca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych;
                          </li>
                          <li>podmiotom, którym przekazanie danych jest niezbędne w celu zapewnienia prawidłowej realizacji usług za pośrednictwem Sklepu, zgodnie z żądaniem konkretnego Klienta - podmiotom świadczącym usługi płatności
                            elektronicznych (w przypadku wyboru takiej opcji płatności), podmiotom zapewniającym dostawę towaru pod wskazany adres (np. usługi kurierskie), którym dane są udostępniane jako odrębnym administratorom lub z
                            którymi Usługodawca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych (w zależności od statusu tych podmiotów pełnionego wobec przekazanych danych osobowych).
                          </li>
                        </ol>
                      </p>
                      <li>Czy dane są przekazywane do państw trzecich lub organizacji międzynarodowych?</li>
                      <br/>
                      <p>
                        <p>Dane osobowe Klientów, przetwarzane są na terenie Europejskiego Obszaru Gospodarczego (EOG). Usługodawca w uzasadnionych przypadkach (związanych z potrzebą zapewnienia funkcjonalności Sklepu i zarządzania jego
                          działaniem) może przekazywać dane osobowe poza teren EOG w ramach korzystania z usług podwykonawców (spośród kategorii odbiorców wskazanych w punkcie 4 wyżej). W takim wypadku Usługodawca gwarantuje wymagane prawnie
                          środki ochrony danych osobowych, którymi będą (w zależności od przypadku):
                        </p>
                        <ol type="a">
                          <li>przekazanie danych do podwykonawcy zlokalizowanego w państwie trzecim, w stosunku do którego została wydana decyzja stwierdzająca odpowiedni stopień ochrony, zgodnie z wymaganiami art. 45 RODO, ii) przekazanie
                            danych realizowane na podstawie zawartej z podwykonawcą umowy o transfer danych opartej na Standardowych Klauzulach Umownych przyjętych w drodze decyzji przez Komisję Europejską, iii) przekazanie danych realizowane
                            w ramach stosowanych przez podwykonawcę wiążących reguł korporacyjnych, o których mowa w art. 47 RODO, iiii) przekazanie danych odbywające się do podwykonawcy, który uczestniczy w programie Privacy Shield. Więcej
                            informacji dotyczących stosowanych przez Usługodawcę środków bezpieczeństwa związanych z przekazywaniem danych poza obszar EOG można uzyskać kontaktując się z Usługodawcą pod wskazany powyżej adres.
                          </li>
                        </ol>
                      </p>
                      <li>W jaki sposób dane osobowe są zabezpieczone?</li>
                      <br/>
                      <p>
                        Administrator danych stosuje adekwatne środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane
                        przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem ustawy oraz zmianą, utratą, uszkodzeniem lub zniszczeniem. Administrator danych opracował i wdrożył
                        wewnętrzne polityki bezpieczeństwa danych, do których stosowania zobowiązani są wszyscy pracownicy i współpracownicy. Dostęp do danych osobowych ograniczony jest wyłącznie do osób upoważnionych, które zobowiązane są do
                        zachowania danych i sposobów ich zabezpieczenia w tajemnicy.<br/>
                        Zabezpieczenie hasłem<br/>
                        Hasło bezpieczne powinno być czysto przypadkowe i składać się ze wszystkich znaków i znaków specjalnych podawanych przez klawiaturę. Podczas tworzenia hasła należy wziąć pod uwagę następujące kwestie: Hasło powinno
                        składać się z: co najmniej dwunastu znaków, wielkich i małych liter, cyfr i znaków specjalnych. Na stronie Sklepu Internetowego przy zakładaniu Konta Klienta znajduje się informacja o sile i bezpieczeństwie hasła.
                        Klient zobowiązany jest do zachowania w poufności wszelkich danych umożliwiających logowanie na stronie internetowej, w tym zwłaszcza nie udostępniania ich nieupoważnionym osobom trzecim. Usługodawcanie bierze
                        odpowiedzialności za bezprawnie użyte hasła dostępu, chyba, że ponosi odpowiedzialność za bezprawne użycie hasła. Po zakończeniu korzystania z Konat Klienta należy się wylogować.
                      </p>
                      <li>Polityka Cookies</li>
                      <br/>
                      <p>
                        <ol type="a">
                          <li>Gdy użytkownik odwiedza Sklep Internetowy używa plików cookies, aby maksymalnie ułatwić mu korzystanie z serwisu.
                          </li>
                          <li>Pliki cookies mogą zawierać informacje dotyczące Użytkownika, jego preferencji lub używanego przez niego urządzenia. Gromadzone informacje nie pozwalają na bezpośrednią identyfikację Użytkownika, lecz mogą
                            umożliwić korzystanie z serwisu w sposób dostosowany do osobistych preferencji.
                          </li>
                          <li>Użytkownik może nie zezwolić na stosowanie niektórych rodzajów plików cookies. Należy jednak mieć na uwadze, że zablokowanie niektórych rodzajów plików cookies może mieć negatywny wpływ na korzystanie ze Sklepu
                            Internetowego i oferowanych przez produktów i usług. Preferencje dotyczące plików cookies wybrane w Serwisie zostaną zapamiętane i będą uwzględniane przy każdej wizycie użytkownika na tej stronie.
                          </li>
                          <li>Zmiana ustawień dotyczących plików cookies jest możliwa za pośrednictwem ustawień przeglądarki.</li>
                          <li>Za pomocą technologii plików cookies zbierane są dane statystyczne, służące do poprawy wygody korzystania z Sklepu Internetowego przez użytkownika. Jedyną informacją o użytkowniku, która jednak bez połączenia z
                            innymi danymi nie pozwala na jego identyfikację, jest adres IP, z którego łączy się użytkownik. Żadne dodatkowe dane o użytkowniku nie są zbierane.
                          </li>
                          <li>Adres IP Użytkownika może być przekazany zewnętrznym podmiotom, w tym Google. Jednak również i w tym przypadku, identyfikacja użytkownika w oparciu o te dane nie jest możliwa.
                          </li>
                          <li>Sklep Internetowy wykorzystuje następujące pliki cookies: analityczne i marketingowe.</li>
                          <li>Z analitycznych i marketingowych plików cookies korzystamy po to, aby ulepszyć funkcjonowanie naszej strony internetowej oraz mierzyć, nie identyfikując danych osobowych, skuteczność podejmowanych przez nas
                            działań marketingowych.
                          </li>
                          <li>Serwis wykorzystuje dwa rodzaje plików cookies: <br />
                          - sesyjne – pozostają na urządzeniu użytkownika aż do opuszczenia strony internetowej lub wyłączenia przeglądarki internetowej;<br />
                          - stałe – pozostają na urządzeniu użytkownika przez określony czas albo do momentu ich ręcznego usunięcia.
                          </li>
                          <li>Przeglądarki internetowe domyślnie dopuszczają przechowywanie plików cookies. Jeśli jednak dany Użytkownik nie wyraża zgody na korzystanie z plików cookies przez stronę internetową Sklepu Internetowego, zawsze
                            może zmienić ustawienia przeglądarki i zapobiec zapisywaniu/odczytywaniu się plików cookies,
                          </li>

                          <li>Należy jednak pamiętać, że efektem zmiany ustawień w przeglądarce może być utrata możliwości korzystania z niektórych funkcji dostępnych w Sklepie Internetowym.
                          </li>
                        </ol>
                      </p>
                      <li>Czy polityka prywatności może się zmienić?</li>
                      <br/>
                      <p>Administrator danych zastrzega możliwość zmiany niniejszej polityki prywatności, w sytuacji, gdy będą tego wymagały zastosowane rozwiązania techniczne lub zmiany przepisów prawa w zakresie prywatności osób, których
                        dane dotyczą. </p>
                        <p>W przypadku zmiany obowiązującej polityki prywatności, wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu.</p>
                        <p>Aktualna wersja polityki prywatności będzie zawsze umieszczona na stronie Sklepu Internetowego, dlatego zaleca się każdorazowe zapoznanie się z aktualnie obowiązującymi uregulowaniami.
                      </p>
                    </ol>
                  </p>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </Segment>
      </MainTopBg>
    );
  }
}